<template>
  <div>
    <v-card>
      <v-row class="mx-2 pt-2">
        <v-col>
          <h3>Integrasi Tokopedia</h3>
        </v-col>
      </v-row>
      <v-divider class="my-4" />
      <div class="px-4 pb-4">
        <v-row class="mb-1">
          <div />
        </v-row>
      </div>
      <v-data-table
        :headers="headers"
        :items="TokenRequestListTokopedia"
        :items-per-page="10"
        item-key="id"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
          'items-per-page-text': 'Jumlah data',
        }"
        :server-items-length="TokenRequestCountTokopedia"
        mobile-breakpoint="0"
        :loading="loadingTokenRequestTokopedia"
        :options.sync="tokenPagination"
      >
        <template
          v-slot:[`item.created_at`]="props"
        >
          <span>{{ formatDate(props.item.created_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) }}</span>
        </template>
        <template
          v-slot:[`item.workspace`]="props"
        >
          <span>{{ props.item.workspace.name }}</span>
        </template>
        <template
          v-slot:[`item.connection_name`]="props"
        >
          <span class="font-weight-bold">{{ props.item.connection_name }}</span>
        </template>
        <template
          v-slot:[`item.shop_url`]="props"
        >
          <span>
            <a
              class="font-weight-bold"
              :href="props.item.shop_url"
              target="_blank"
            >{{ props.item.shop_url }}
            </a>
          </span>
        </template>
        <template
          v-slot:[`item.status`]="props"
        >
          <v-chip
            :color="props.item.status ? 'success' : 'warning'"
            small
            outlined
          >
            {{ props.item.status ? 'Added' : 'Pending' }}
          </v-chip>
        </template>
        <template v-slot:[`item.action`]="props">
          <v-btn
            v-if="!props.item.status"
            color="primary"
            small
            @click="confirmChangeStatus(props.item.id)"
          >
            Konfirmasi
          </v-btn>
          <v-btn
            v-if="!props.item.status"
            color="error"
            small
            class="ml-2"
            @click="confirmDeleteToken(props.item.id, props.item.workspace.id)"
          >
            Tolak
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <tokopedia-confirm-add
      ref="tokopediaConfirmAddDialog"
      @success="fetchRequestList(true)"
    />
  </div>
</template>
<script>
import useTokopedia from '@/composables/useTokopedia'
import { onMounted, ref, watch } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import Vue from 'vue'
import useIntegration from '@/composables/useIntegration'
import TokopediaConfirmAdd from './forms/TokopediaConfirmAdd.vue'

export default {
  components: {
    TokopediaConfirmAdd,
  },
  setup() {
    const tokopediaConfirmAddDialog = ref(null)
    const {
      fetchTokopediaIntegrateRequests, loadingTokenRequestTokopedia, TokenRequestListTokopedia, TokenRequestCountTokopedia,
      // changeStatusTokopediaToken, loadingChangeStatusTokopediaToken,
    } = useTokopedia()

    const { removeWorkspaceToken, loadingDeleteToken } = useIntegration()
    const headers = [
      {
        text: 'Date',
        value: 'created_at',
      },
      {
        text: 'Workspace',
        value: 'workspace',
      },
      {
        text: 'Nama Toko',
        value: 'connection_name',
      },
      {
        text: 'Link Toko',
        value: 'shop_url',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Aksi',
        value: 'action',
      },
    ]

    const tokenPagination = ref({})

    const fetchRequestList = (initial = false) => {
      fetchTokopediaIntegrateRequests({
        limit: tokenPagination.value.itemsPerPage ? tokenPagination.value.itemsPerPage : 10,
        offset: tokenPagination.value.page && !initial ? (tokenPagination.value.page - 1) * tokenPagination.value.itemsPerPage : 0,
      })
    }

    const confirmChangeStatus = id => {
      // Vue.$dialog({
      //   title: 'Konfirmasi',
      //   body: 'Apakah anda yakin ingin mengubah status token ini?',
      // }).then(result => {
      //   if (result) {
      //     changeStatusTokopediaToken(id).then(() => {
      //       fetchRequestList()
      //     })
      //   }
      // })
      tokopediaConfirmAddDialog.value.open(id)
    }

    const confirmDeleteToken = (id, workspaceId) => {
      Vue.$dialog({
        title: 'Konfirmasi',
        body: 'Apakah anda yakin ingin menolak permintaan token ini?',
      }).then(result => {
        if (result) {
          removeWorkspaceToken(id, workspaceId).then(() => {
            fetchRequestList()
          })
        }
      })
    }

    watch(tokenPagination, () => {
      fetchRequestList()
    })

    onMounted(() => {
      fetchRequestList()
    })

    return {
      headers,
      loadingTokenRequestTokopedia,
      TokenRequestListTokopedia,
      TokenRequestCountTokopedia,
      formatDate,
      // loadingChangeStatusTokopediaToken,
      confirmChangeStatus,
      tokenPagination,
      confirmDeleteToken,
      loadingDeleteToken,
      tokopediaConfirmAddDialog,
    }
  },
}
</script>
